import { useContext } from "react";
import LanguageContext from "../LanguageContext";

import dynamic from "next/dynamic";

const EstimateB2BForm = dynamic(
  () => import("../ModalForms/EstimateB2BForm/EstimateB2BForm"),
  {
    ssr: false,
  }
);

const FindCourseForm = dynamic(
  () => import("../ModalForms/FindCourseForm/FindCourseForm"),
  {
    ssr: false,
  }
);

const JoinOurTeam = dynamic(
  () => import("../ModalForms/JoinOurTeam/JoinOurTeam"),
  {
    ssr: false,
  }
);

const VerifyLevelForm = dynamic(
  () => import("../ModalForms/VerifyLevelForm/VerifyLevelForm"),
  {
    ssr: false,
  }
);

const PurchaseNow = dynamic(() => import("../PurchaseNow/PurchaseNow"), {
  ssr: false,
});

import { Languages } from "../LanguageContext";

const translations = {
  [Languages.English]: {
    verifyTitle: "We assess your current language level for free",
    verifyUnderTitle:
      "Fill out the form and we'll reach out to you with details about the free language level placement test.",
    verifyNormalText:
      "We'll happily answer any of your questions and help you find the best language course.",
    verifyContactText: "Or contact us directly",
    verifyButtonContactText: "Visit contact page",
    joinTeamTitle: "Join our team",
    joinTeamSubtitle:
      "Leave us your CV, contact data and prefferable meeting time, and we’ll reach out to you with details.",
    joinTeamNormalText:
      "Also we’ll happily answer any of your questions and help you.",
    joinTeamContactText:
      "You can also contact us directly to book a meeting time.",
  },
  [Languages.German]: {
    verifyTitle: "Wir stufen Ihr Sprachniveau gratis ein",
    verifyUnderTitle:
      "Füllen Sie das Formular aus und wir kontaktieren Sie, um einen Termin zum Einstufungstest zu vereinbaren.",
    verifyNormalText:
      "Gerne beantworten wir Ihre Fragen und helfen Ihnen dabei, den für Sie passenden Sprachunterricht zu finden.",
    verifyContactText:
      "Sie können direkt mit uns Kontakt aufnehmen und Ihren Einstufungstest persönlich vereinbaren.",
    verifyButtonContactText: "Kontaktseite besuchen",
    joinTeamTitle: "Auf ein gutes Kennenlernen!",
    joinTeamSubtitle:
      "Gerne nehmen wir Ihr CV, Ihre Kontakdaten und Errreichbarkeit entgegen.",
    joinTeamNormalText: "Das LcL-Sprachteam ist gespannt auf Ihre Bewerbung.",
    joinTeamContactText:
      "Nehmen Sie mit uns auch direkt Kontakt auf und vereinbaren Sie einen Kennenlerntermin!",
  },
};

export const setModalTypes = () => {
  const { language } = useContext(LanguageContext);

  return {
    verify_level: {
      element: <VerifyLevelForm />,
      fullWidthForm: false,
      title: translations[language].verifyTitle,
      boldTextUnderTitle: translations[language].verifyUnderTitle,
      normalText: translations[language].verifyNormalText,
      contactText: translations[language].verifyContactText,
      phone: "+43 1 202 18 58",
      mail: "team@lcl-institut.at",
      buttonText: translations[language].verifyButtonContactText,
    },
    join_our_team: {
      element: <JoinOurTeam />,
      fullWidthForm: false,
      title: translations[language].joinTeamTitle,
      boldTextUnderTitle: translations[language].joinTeamSubtitle,
      normalText: translations[language].joinTeamNormalText,
      contactText: translations[language].joinTeamContactText,
      phone: "+43 1 202 18 58",
      mail: "team@lcl-institut.at",
      buttonText: translations[language].verifyButtonContactText,
    },
    estimate_b2b: {
      element: <EstimateB2BForm />,
      fullWidthForm: true,
    },
    find_course: {
      element: <FindCourseForm />,
      fullWidthForm: true,
    },
    purchase_now: {
      element: <PurchaseNow />,
      fullWidthForm: true,
    },
  };
};
