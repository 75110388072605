import { Router, useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import { setModalTypes } from "./ModalTypes";
import queryString from "query-string";
import { ModalProps } from "../ModalForms/ModalProps";

import dynamic from "next/dynamic";

const ModalForms = dynamic(() => import("../ModalForms/ModalForms"), {
  ssr: false,
});

interface SingleElement {
  element: Element;
}

type SingleType = SingleElement & ModalProps;

const ModalFormsChecker = () => {
  const [modal, setModal] = useState<SingleType | null>(null);
  const [modalShow, setModalShow] = useState(false);
  const MODAL_TYPES = setModalTypes();
  const { query, push, asPath: path } = useRouter();
  const router = useRouter();
  const getModalFromParams = () => {
    const { modal } = query;
    return modal;
  };

  const onHideModal = () => {
    const url = window.location.href;
    const urlObj = new URL(url);

    urlObj.hash = "";
    urlObj.search = "";
    history.pushState("", document.title, urlObj.pathname);

    setModalShow(false);
  };

  useEffect(() => {
    const modalName = path.split("#")[1];
    const isModal = Object.keys(MODAL_TYPES).includes(modalName);

    if (!modalName || !isModal) return;
    // @ts-expect-error
    setModal(MODAL_TYPES[modalName]);
    setModalShow(true);
  }, [path]);

  useEffect(() => {
    const modalName = getModalFromParams();

    if (!modalName) return;
    // @ts-expect-error
    setModal(MODAL_TYPES[modalName]);
  }, [query]);

  useEffect(() => {
    const onHashChanged = (url: string) => {
      const modalName = url.split("#")[1];
      const isModal = Object.keys(MODAL_TYPES).includes(modalName);

      if (!modalName || !isModal) return;
      // @ts-expect-error
      setModal(MODAL_TYPES[modalName]);
      setModalShow(true);
    };

    router.events.on("routeChangeStart", onHashChanged);
    router.events.on("hashChangeStart", onHashChanged);

    return () => {
      router.events.off("routeChangeStart", onHashChanged);
      router.events.off("hashChangeStart", onHashChanged);
    };
  }, [router.events]);

  return (
    <ModalForms
      fullWidthForm={modal?.fullWidthForm}
      modalShow={modalShow}
      modalHide={onHideModal}
      form={modal?.element}
      title={modal?.title}
      boldTextUnderTitle={modal?.boldTextUnderTitle}
      normalText={modal?.normalText}
      contactText={modal?.contactText}
      phone={modal?.phone}
      mail={modal?.mail}
      buttonText={modal?.buttonText}
    />
  );
};

export default ModalFormsChecker;
