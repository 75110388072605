import Head from "next/head";
import React from "react";
import Footer from "../components/Footer";
import Navigation from "../components/Navigation";
import WordpressApi, { Menus } from "../services/wordpressApi";
import ComposerBlocks from "../components/ComposerBlocks";
import Seo from "../components/Seo/Seo";
import { LanguageContextProvider } from "../components/LanguageContext";
import ModalFormsChecker from "../components/ModalFormsChecker/ModalFormsChecker";
import PrivateClasses from "../components/PrivateClasses/PrivateClasses";

export default function Home({
  data,
  menus,
  tags,
  htmlHead,
  lang = "de",
}: {
  data: any;
  menus: Menus;
  tags: any;
  htmlHead: string;
  lang: string;
}) {
  return (
    <div>
      <Seo header={htmlHead} />
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=2.0, minimal-scale=1.0"
        />
      </Head>
      <LanguageContextProvider lang={lang}>
        <Navigation menu={menus.primary} />
        <main id="bootstrap-overrides">
          <ComposerBlocks data={data} />
          <ModalFormsChecker />
        </main>
        <Footer {...menus} />
      </LanguageContextProvider>
    </div>
  );
}

export async function getServerSideProps({ pathname }: any) {
  let data: any;
  let menus = {};
  let header = "";

  try {
    [data, menus] = await Promise.all([
      WordpressApi.getPage("home-page"),
      WordpressApi.getMenus(),
    ]);
  } catch (e) {
    console.log(e);
  }

  return {
    props: {
      data: data.blocks,
      tags: data.tags,
      header: header,
      htmlHead: data?.htmlHead,
      menus,
    },
  };
}
